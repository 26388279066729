import { render, staticRenderFns } from "./404page.vue?vue&type=template&id=7166a118&"
import script from "./404page.vue?vue&type=script&lang=js&"
export * from "./404page.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import KLayout from '@web/components/core/k-layout'
import KLink from '@web/components/core/k-link'
installComponents(component, {KLayout,KLink})
